.projects-container {
  display: grid;
  grid-template-columns:  1fr 1fr;
  grid-gap: 1rem;
  margin: 0 1rem 1rem 1rem;
}

.projects {
  color: white;
  background-size: cover;
  background-position: center;
  transition: all 0.8s ease;
  height: 500px;
}

.projects:hover {
  transform: skew(-1deg);
}

.projects h2, p {
  text-align: left;
  padding: 5px;
}

.projects p {
  font-weight: 500;
  padding: 5px;
}

.projects-a {
  color: white;
  text-decoration: none;
}

.projects a:hover {
  color: rgba(255, 255, 255, 0.623);
}

.codebase-button {
  margin: auto 0;
  border: none;
  background-color: black;
  color: white;
  border-radius: 30px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-in-out;
}

.codebase-button a {
  color: white;
  text-decoration: none;
}

.codebase-button:hover {
  background-color: rgba(0, 0, 0, 0.644);
}

@media only screen and (min-width: 1px) and (max-width: 768px) {
  .projects-container {
    display: block;
  }

  .projects {
    margin-bottom: 1rem;
    height: 400px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 550px) {
  .projects {
    height: 450px;
  }
}
