.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#cog-spinner {
  height: 120px;
  color: black;
}
