.about-container {
  display: flex;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(290deg,#00aeff61, #ff8800b8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  height: auto;
}

.img {
  margin: 0 1rem 1rem 0;
  background-image: url("https://res.cloudinary.com/dfipoufmj/image/upload/v1682418454/IMG_9754_e2lldt.jpg");
  background-size: cover;
  object-fit: cover;
  background-position: center;
  width: 100%;
  height: auto;
  max-width: 370px;
}

.about h1, p {
  text-align: left;
  padding: 1rem;
  max-width: 80%;
}

.about p {
  margin-top: 40px;
  padding-top: 0;
}

.about-buttons {
  display: flex;

}

.about ul {
  padding: 1rem;
  list-style: none;
  margin-bottom: 0;
  display: flex;
}

.about li {
  height: 50px;
  width: 50px;
  margin: auto 20px auto 0;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

#linkedin:hover {
  background-color: #0A66C2;
  color: white;
}

#github:hover {
  background-color: #181717;
  color: white;
}

#facebook:hover {
  color: white;
  background-color: #1877F2;
}

#instagram:hover {
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

}

.svg-inline--fa {
  margin: auto;
  height: 20px;
}


.about button {
  margin: auto 16px;
  border: none;
  background-color: black;
  color: white;
  border-radius: 30px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-in-out;
}

.about button:hover {
  background-color: rgba(0, 0, 0, 0.644);
}

#contact-button-a {
  text-decoration: none;
  color: white;
}

.social-media-icon {
  color: black;
}

@media only screen and (min-width: 1px) and (max-width: 768px) {
  .about-container {
    display: block;
  }

  .about {
    width: auto;
    min-width: none !important;
  }

  .img {
    width: auto;
    margin-left: 1rem;
    margin-bottom: 1rem;
    height: 280px;
    max-width: none;
  }

  .about button {
    height: 40px;
    font-size: .8rem;
  }

  .about li {
    height: 40px;
    width: 40px;
  }

  .svg-inline--fa {
    height: 20px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 550px) {
  .about-buttons {
    display: block;
  }

  .about-buttons ul {
    display: flex;
  }

  .about h1 {
    font-size: 1.6rem;
  }

  .about {
    padding: 10px;
  }
}
