.navbar {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar-logo {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.navbar-logo:hover {
  color: rgba(0, 0, 0, 0.644);
}

.navbar-toggle {
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
}

.navbar-toggle p {
  margin-right: 0.5rem;
}

.navbar-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: 0%;
  padding: 0;
  margin: 0;
  color: black;
  list-style: none;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  visibility: hidden;
}

.navbar-menu.active {
  display: block;
  visibility: visible;
  width: 100%;
  height: 100vh;
  right: 0;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  font-size: 2rem;
}

.navbar-menu.active {
  transform: translateX(0%);
}

.navbar-item {
  margin: 0.5rem 0;
}

.navbar-link {
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  text-align: left;
}

.navbar-link:hover {
  color: rgba(0, 0, 0, 0.644);
}

.bars:hover {
  color: rgba(0, 0, 0, 0.644);
}

@media (min-width: 768px) {
  .navbar {
    flex-direction: row;
    padding: 0.5rem 1rem;
  }

  .navbar-container {
    justify-content: space-between;
  }

  .navbar-menu {
    display: flex;
    flex-direction: row;
    position: static;
    width: auto;
    opacity: 1;
    visibility: visible;
    margin: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-item {
    margin: 0;
  }
}
